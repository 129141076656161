<!--
 * @Author: Han
 * @Date: 2021-12-15 16:36:27
 * @LastEditors: Han
 * @LastEditTime: 2021-12-29 17:21:47
 * @FilePath: \1\minepage\src\components\Footer.vue
-->
<template>
    <div class="vvhan-footer">
        <div class="vvhan-menu" @click="setShow">
            <svg t="1639560596061" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3718"
                width="34" height="34">
                <path fill="#333333"
                    d="M357.33669 28.999371H84.098175C37.699182 28.999371 1.611076 65.087476 1.611076 111.486469v273.238515c0 46.398993 36.088106 82.487099 82.487099 82.487099h273.238515c46.398993 0 82.487099-36.088106 82.487099-82.487099V111.486469c2.577722-46.398993-36.088106-82.487099-82.487099-82.487098zM707.90686 454.323474c20.621775 20.621775 43.821271 28.35494 69.598489 28.35494 25.777218 0 51.554437-10.310887 69.59849-28.35494l141.774701-141.774701c38.665828-38.665828 38.665828-103.108874 0-141.774701l-141.774701-141.774701c-38.665828-38.665828-103.108874-38.665828-141.774701 0L568.70988 170.774072c-38.665828 38.665828-38.665828 103.108874 0 141.774701l139.19698 141.774701z m-85.064821-226.839522l141.774701-141.774701c2.577722-5.155444 10.310887-7.733166 15.466331-7.733165 5.155444 0 10.310887 2.577722 15.466331 7.733165l141.774701 141.774701c7.733166 7.733166 7.733166 23.199497 0 30.932662l-141.774701 141.774701c-7.733166 7.733166-23.199497 7.733166-30.932662 0L622.842039 258.416614c-10.310887-10.310887-10.310887-23.199497 0-30.932662zM357.33669 585.787288H84.098175c-46.398993 0-82.487099 36.088106-82.487099 82.487098v273.238515c0 46.398993 36.088106 82.487099 82.487099 82.487099h273.238515c46.398993 0 82.487099-36.088106 82.487099-82.487099V668.274386c2.577722-46.398993-36.088106-82.487099-82.487099-82.487098zM916.702329 585.787288H643.463814c-46.398993 0-82.487099 36.088106-82.487099 82.487098v273.238515c0 46.398993 36.088106 82.487099 82.487099 82.487099h273.238515c46.398993 0 82.487099-36.088106 82.487098-82.487099V668.274386c0-46.398993-36.088106-82.487099-82.487098-82.487098z"
                    p-id="3719">
                </path>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            setShow() {
                this.$emit('setShow')
            }
        }
    }
</script>

<style lang="less" scoped>
    .vvhan-footer {
        .vvhan-menu {
            position: fixed;
            bottom: 68px;
            right: 68px;
            width: 34px;
            height: 34px;
            color: orange;

            svg {
                position: absolute;
                cursor: pointer;
            }
        }

        .slide-fade-enter-active {
            transition: all .2s ease;
        }

        .slide-fade-leave-active {
            transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        }

        .slide-fade-enter,
        .slide-fade-leave-to {
            transform: translateX(5px);
            opacity: 0;
        }

    }

    @media (max-width: 768px) {
        .vvhan-footer {
            .vvhan-menu {
                right: 38px;
                width: 26px;
                height: 26px;
            }
        }
    }
</style>